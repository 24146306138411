// IMPORT DEPENDENCIES
import { storage } from "."
import swahili from './swahili';
import english from './english';

// TRANSLATOR
const translate = (text) => {
    try {

        if (text && (text.trim().length > 0) && (swahili.length === english.length)) {

            // RETREVING USER CURRENT LANGUAge
            const currentLanguage = storage.retrieve("language")

            // remove special _ character on text
            let translatedWord = text.trim().replace(/_/g, " ").toLowerCase()

            const wordExistInEnglish = english.find((word) => (word.trim().toLowerCase() === translatedWord))
            const wordExistInSwahili = swahili.find((word) => (word.trim().toLowerCase() === translatedWord))

            // translating from english to swahili
            if (wordExistInEnglish && (currentLanguage === "swahili"))
                translatedWord = swahili[english.indexOf(wordExistInEnglish)]

            // translating from swahili to english
            else if (wordExistInSwahili && (currentLanguage === "english"))
                translatedWord = english[swahili.indexOf(wordExistInSwahili)]

            // returning translated text
            return translatedWord[0].toUpperCase() + translatedWord.toLowerCase()?.substring(1)

        }

        return text

    } catch (error) {
        console.log(`Translation error: ${(error instanceof Error).message}`)
        return text
    }
}


// EXPORT
export default translate