import React, { useContext } from "react";
import Select from "react-select";
import translate from "../helpers/translator";
import { ThemeContext } from "../context/themeContext";

export const Icon = React.memo((props) => (
  <i
    id={props.id}
    onClick={props.onClick}
    className={`material-icons-${props.type} ${props.color} hide-on-print ${props.position} ${props.rotate ? "rotate" : ""}`}
  >
    {props.name}
  </i>
));

const getCustomStyles = (theme) => ({
  control: (styles, { isFocused }) => ({
    ...styles,
    marginTop: ".2rem",
    backgroundColor: theme === 'dark' ? 'rgb(22, 27, 34)' : 'rgb(246, 248, 250)',
    borderColor: 'transparent',
    boxShadow: isFocused ? 'none' : styles.boxShadow,
    '&:hover': {
      borderColor: 'transparent',
    },
    color: theme === 'dark' ? 'white' : 'black',
    transition: "none"
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: theme === 'dark'
      ? isSelected
        ? 'rgba(36, 36, 36, 0.7)'
        : isFocused
          ? 'rgba(36, 36, 36, 0.5)'
          : 'rgb(22, 27, 34)'
      : isSelected
        ? 'rgb(246, 248, 250)'
        : isFocused
          ? 'rgba(240, 240, 249, 0.7)'
          : 'white',
    color: theme === 'dark' ? 'white' : 'black',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'gray',
    }
  }),
  placeholder: (styles) => ({
    ...styles,
    color: 'gray',
  }),
  valueContainer: (styles) => ({
    ...styles,
    height: '3.257rem',
    textAline: "center",
    color: 'gray',
    transition: "none"
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'blue', // Selected text color is blue
    backgroundColor: "blue",
  }),
  singleValueLabel: (styles) => ({
    ...styles,
    color: 'white',
  }),
  singleValueRemove: (styles) => ({
    ...styles,
    color: 'red',
    ':hover': {
      backgroundColor: theme === 'dark' ? 'rgba(36, 36, 36, 0.7)' : 'rgb(246, 248, 250)',
      color: 'red',
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    color: 'white',
    backgroundColor: "blue",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'red',
    ':hover': {
      backgroundColor: theme === 'dark' ? 'rgba(36, 36, 36, 0.7)' : 'rgb(246, 248, 250)',
      color: 'red',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'gray',
    '&:hover': {
      color: 'gray',
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: theme === 'dark' ? 'rgb(22, 27, 34)' : 'white',
    color: theme === 'dark' ? 'white' : 'black',
  }),
});

// MULTI SELECT
export const MultiSelect = React.memo((props) => {
  // get theme from context
  const { theme } = useContext(ThemeContext);

  const labelWithAsterisk = translate(props.label);
  return (
    <>
      <label htmlFor={props.name}>
        {labelWithAsterisk && labelWithAsterisk.endsWith('*') ? (
          <>
            {labelWithAsterisk.slice(0, -1)}
            <span className="red-asterisk">*</span>
          </>
        ) : (
          labelWithAsterisk
        )}
      </label>
      <Select
        name={props.name}
        key={props.reskey}
        value={props.value}
        isMulti={props.isMulti}
        options={props.options}
        onChange={props.onChange}
        className={props.className}
        autoFocus={props.autoFocus}
        isDisabled={props.isDisabled}
        styles={getCustomStyles(theme)}
        isClearable={props.isClearable}
        isSearchable={props.isSearchable}
        placeholder={translate(props.placeholder)}
        noOptionsMessage={props.noOptionsMessage}
        required={props.required || false}
      />
      <span className="helper-text">{translate(props.error)}</span>
    </>
  );
});

