// IMPORT DEPENDENCIES
import { Switch, Route } from 'react-router-dom';
import routes from './routes';

const routing = (application) => {
  try {

    return (
      <Switch>
        {
          routes.map((route, index) => (
            (!application.reducer.state.authenticated && route.guest) || (application.reducer.state.authenticated && !route.guest) ?
              (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  render={(props) => (
                    < route.component {...props} application={application} />
                  )}

                />
              ) : null
          ))
        }
      </Switch>
    )

  } catch (error) {
    console.error(error)
    return null
  }
}

// EXPORT
export default routing