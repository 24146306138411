// IMPORT DEPENDENCIES
import React from 'react'

// SPINNER
const Spinner = React.memo(() => {
    return (
        <div className="loader-container">
            <div className="loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
})

// EXPORT
export default Spinner
