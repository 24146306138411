import { lazy } from 'react';

const routes = [
    // home
    {
        path: '/',
        guest: true,
        component: lazy(() => import('../pages/home/home'))
    },

    // login
    {
        guest: true,
        path: "/login",
        component: lazy(() => import('../pages/user/login'))
    },

    // dashboard
    {
        guest: false,
        path: "/dashboard",
        component: lazy(() => import('../pages/dashboard/dashboard'))
    },

    {
        guest: false,
        path: "/",
        component: lazy(() => import('../pages/dashboard/dashboard'))
    },

    // level
    {
        guest: false,
        path: "/create-level",
        component: lazy(() => import('../pages/level/form'))
    },
    {
        guest: false,
        path: "/edit-level",
        component: lazy(() => import('../pages/level/form'))
    },
    {
        guest: false,
        path: "/list-level",
        component: lazy(() => import('../pages/level/list'))
    },
    {
        guest: false,
        path: "/view-level",
        component: lazy(() => import('../pages/level/view'))
    },

    // necta
    {
        guest: false,
        path: "/view-necta-result",
        component: lazy(() => import('../pages/necta/form'))
    },
    {
        guest: false,
        path: "/view-necta-results",
        component: lazy(() => import('../pages/necta/list'))
    },
    // role
    {
        guest: false,
        path: "/create-role",
        component: lazy(() => import('../pages/role/form'))
    },
    {
        guest: false,
        path: "/edit-role",
        component: lazy(() => import('../pages/role/form'))
    },
    {
        guest: false,
        path: "/list-role",
        component: lazy(() => import('../pages/role/list'))
    },
    {
        guest: false,
        path: "/view-role",
        component: lazy(() => import('../pages/role/view'))
    },

    // item
    {
        guest: false,
        path: "/create-item",
        component: lazy(() => import('../pages/item/form'))
    },
    {
        guest: false,
        path: "/edit-item",
        component: lazy(() => import('../pages/item/form'))
    },
    {
        guest: false,
        path: "/list-item",
        component: lazy(() => import('../pages/item/list'))
    },
    {
        guest: false,
        path: "/view-item",
        component: lazy(() => import('../pages/item/view'))
    },

    // financial statements
    {
        guest: false,
        path: "/financial-statements",
        component: lazy(() => import('../pages/financialStatement/form'))
    },

    // vendor
    {
        guest: false,
        path: "/create-vendor",
        component: lazy(() => import('../pages/vendor/form'))
    },
    {
        guest: false,
        path: "/edit-vendor",
        component: lazy(() => import('../pages/vendor/form'))
    },
    {
        guest: false,
        path: "/list-vendor",
        component: lazy(() => import('../pages/vendor/list'))
    },
    {
        guest: false,
        path: "/view-vendor",
        component: lazy(() => import('../pages/vendor/view'))
    },

    // warehouse
    {
        guest: false,
        path: "/create-warehouse",
        component: lazy(() => import('../pages/warehouse/form'))
    },
    {
        guest: false,
        path: "/edit-warehouse",
        component: lazy(() => import('../pages/warehouse/form'))
    },
    {
        guest: false,
        path: "/list-warehouse",
        component: lazy(() => import('../pages/warehouse/list'))
    },
    {
        guest: false,
        path: "/view-warehouse",
        component: lazy(() => import('../pages/warehouse/view'))
    },

    // user
    {
        guest: false,
        path: "/create-user",
        component: lazy(() => import('../pages/user/form'))
    },
    {
        guest: false,
        path: "/edit-user",
        component: lazy(() => import('../pages/user/form'))
    },
    {
        guest: false,
        path: "/list-user",
        component: lazy(() => import('../pages/user/list'))
    },
    {
        guest: false,
        path: "/view-user",
        component: lazy(() => import('../pages/user/view'))
    },
    {
        guest: false,
        path: '/edit-profile',
        component: lazy(() => import('../pages/user/form'))
    },
    {
        guest: false,
        path: '/view-profile',
        component: lazy(() => import('../pages/user/view'))
    },
    {
        guest: false,
        path: '/change-password',
        component: lazy(() => import('../pages/user/changePassword'))
    },
    {
        guest: true,
        path: '/forgot-password',
        component: lazy(() => import('../pages/user/forgot'))
    },
    {
        guest: true,
        path: '/reset-code',
        component: lazy(() => import('../pages/user/code'))
    },
    {
        guest: true,
        path: '/password-reset',
        component: lazy(() => import('../pages/user/reset'))
    },

    // student
    {
        guest: false,
        path: "/create-student",
        component: lazy(() => import('../pages/student/form'))
    },
    {
        guest: false,
        path: "/edit-student",
        component: lazy(() => import('../pages/student/form'))
    },
    {
        guest: false,
        path: "/list-student",
        component: lazy(() => import('../pages/student/list'))
    },
    {
        guest: false,
        path: "/view-student",
        component: lazy(() => import('../pages/student/view'))
    },

    // parent
    {
        guest: false,
        path: "/create-parent",
        component: lazy(() => import('../pages/parent/form'))
    },
    {
        guest: false,
        path: "/edit-parent",
        component: lazy(() => import('../pages/parent/form'))
    },
    {
        guest: false,
        path: "/list-parent",
        component: lazy(() => import('../pages/parent/list'))
    },

    // teacher
    {
        guest: false,
        path: "/create-teacher",
        component: lazy(() => import('../pages/teacher/form'))
    },
    {
        guest: false,
        path: "/edit-teacher",
        component: lazy(() => import('../pages/teacher/form'))
    },
    {
        guest: false,
        path: "/list-teacher",
        component: lazy(() => import('../pages/teacher/list'))
    },

    // staff
    {
        guest: false,
        path: "/create-staff",
        component: lazy(() => import('../pages/staff/form'))
    },
    {
        guest: false,
        path: "/list-staff",
        component: lazy(() => import('../pages/staff/list'))
    },

    // sponsor
    {
        guest: false,
        path: "/create-sponsor",
        component: lazy(() => import('../pages/sponsor/form'))
    },
    {
        guest: false,
        path: "/list-sponsor",
        component: lazy(() => import('../pages/sponsor/list'))
    },

    // topic
    {
        guest: false,
        path: "/create-topic",
        component: lazy(() => import('../pages/topic/form'))
    },
    {
        guest: false,
        path: "/edit-topic",
        component: lazy(() => import('../pages/topic/form'))
    },
    {
        guest: false,
        path: "/list-topic",
        component: lazy(() => import('../pages/topic/list'))
    },
    {
        guest: false,
        path: "/view-topic",
        component: lazy(() => import('../pages/topic/view'))
    },

    // reference
    {
        guest: false,
        path: "/create-reference",
        component: lazy(() => import('../pages/reference/form'))
    },
    {
        guest: false,
        path: "/edit-reference",
        component: lazy(() => import('../pages/reference/form'))
    },
    {
        guest: false,
        path: "/list-reference",
        component: lazy(() => import('../pages/reference/list'))
    },
    {
        guest: false,
        path: "/view-reference",
        component: lazy(() => import('../pages/reference/view'))
    },


    // sof
    {
        guest: false,
        path: "/create-scheme-of-work",
        component: lazy(() => import('../pages/schemeOfWork/form'))
    },
    {
        guest: false,
        path: "/edit-scheme-of-work",
        component: lazy(() => import('../pages/schemeOfWork/form'))
    },
    {
        guest: false,
        path: "/list-scheme-of-work",
        component: lazy(() => import('../pages/schemeOfWork/list'))
    },
    {
        guest: false,
        path: "/list-scheme-of-works",
        component: lazy(() => import('../pages/schemeOfWork/scheme'))
    },

    // log book
    {
        guest: false,
        path: "/create-log-book",
        component: lazy(() => import('../pages/logBook/form'))
    },
    {
        guest: false,
        path: "/edit-log-book",
        component: lazy(() => import('../pages/logBook/form'))
    },
    {
        guest: false,
        path: "/list-log-book",
        component: lazy(() => import('../pages/logBook/list'))
    },
    {
        guest: false,
        path: "/list-log-books",
        component: lazy(() => import('../pages/logBook/log'))
    },

    // lesson plan
    {
        guest: false,
        path: "/create-lesson-plan",
        component: lazy(() => import('../pages/lessonPlan/form'))
    },
    {
        guest: false,
        path: "/list-lesson-plan",
        component: lazy(() => import('../pages/lessonPlan/list'))
    },

    // message
    {
        guest: false,
        path: '/create-message',
        component: lazy(() => import('../pages/message/form'))
    },

    // analysis
    {
        guest: false,
        path: '/analysis',
        component: lazy(() => import('../pages/analysis/form'))
    },

    // term
    {
        guest: false,
        path: "/create-term",
        component: lazy(() => import('../pages/term/form'))
    },
    {
        guest: false,
        path: "/edit-term",
        component: lazy(() => import('../pages/term/form'))
    },
    {
        guest: false,
        path: "/list-term",
        component: lazy(() => import('../pages/term/list'))
    },
    {
        guest: false,
        path: "/view-term",
        component: lazy(() => import('../pages/term/view'))
    },

    // installment
    {
        guest: false,
        path: "/create-installment",
        component: lazy(() => import('../pages/installment/form'))
    },
    {
        guest: false,
        path: "/edit-installment",
        component: lazy(() => import('../pages/installment/form'))
    },
    {
        guest: false,
        path: "/list-installment",
        component: lazy(() => import('../pages/installment/list'))
    },
    {
        guest: false,
        path: "/view-installment",
        component: lazy(() => import('../pages/installment/view'))
    },

    // class
    {
        guest: false,
        path: "/create-class",
        component: lazy(() => import('../pages/class/form'))
    },
    {
        guest: false,
        path: "/edit-class",
        component: lazy(() => import('../pages/class/form'))
    },
    {
        guest: false,
        path: "/list-class",
        component: lazy(() => import('../pages/class/list'))
    },
    {
        guest: false,
        path: "/view-class",
        component: lazy(() => import('../pages/class/view'))
    },

    // stream
    {
        guest: false,
        path: "/create-stream",
        component: lazy(() => import('../pages/stream/form'))
    },
    {
        guest: false,
        path: "/edit-stream",
        component: lazy(() => import('../pages/stream/form'))
    },
    {
        guest: false,
        path: "/list-stream",
        component: lazy(() => import('../pages/stream/list'))
    },
    {
        guest: false,
        path: "/view-stream",
        component: lazy(() => import('../pages/stream/view'))
    },

    // subject
    {
        guest: false,
        path: "/create-subject",
        component: lazy(() => import('../pages/subject/form'))
    },
    {
        guest: false,
        path: "/edit-subject",
        component: lazy(() => import('../pages/subject/form'))
    },
    {
        guest: false,
        path: "/list-subject",
        component: lazy(() => import('../pages/subject/list'))
    },
    {
        guest: false,
        path: "/view-subject",
        component: lazy(() => import('../pages/subject/view'))
    },

    // fee
    {
        guest: false,
        path: "/define-fees",
        component: lazy(() => import('../pages/fees/form'))
    },
    {
        guest: false,
        path: "/edit-fees",
        component: lazy(() => import('../pages/fees/form'))
    },
    {
        guest: false,
        path: "/list-fees",
        component: lazy(() => import('../pages/fees/list'))
    },
    {
        guest: false,
        path: "/view-fees",
        component: lazy(() => import('../pages/fees/view'))
    },
    {
        guest: false,
        path: '/opening-balance',
        component: lazy(() => import('../pages/fees/balance'))
    },
    {
        guest: false,
        path: "/structure-fees",
        component: lazy(() => import('../pages/fees/structure'))
    },
    {
        guest: false,
        path: "/unsubscribe-fees",
        component: lazy(() => import('../pages/fees/unsubscribe'))
    },
    {
        guest: false,
        path: "/list-structure-fees",
        component: lazy(() => import('../pages/fees/listStructure'))
    },
    {
        guest: false,
        path: "/view-structure-fees",
        component: lazy(() => import('../pages/fees/viewfeeStructure'))
    },
    {
        guest: false,
        path: "/edit-structure-fees",
        component: lazy(() => import('../pages/fees/structure'))
    },
    {
        guest: false,
        path: '/create-fee-discount',
        component: lazy(() => import('../pages/fees/discount'))
    },

    // invoice
    {
        guest: false,
        path: '/create-invoice',
        component: lazy(() => import('../pages/invoice/form'))
    },
    {
        guest: false,
        path: '/list-invoice',
        component: lazy(() => import('../pages/invoice/list'))
    },
    {
        guest: false,
        path: '/view-invoice',
        component: lazy(() => import('../pages/invoice/view'))
    },

    // payment
    {
        guest: false,
        path: '/pay-invoice',
        component: lazy(() => import('../pages/payment/form'))
    },

    // result
    {
        guest: false,
        path: '/create-result',
        component: lazy(() => import('../pages/result/form'))
    },
    {
        guest: false,
        path: '/list-result',
        component: lazy(() => import('../pages/result/list'))
    },
    {
        guest: false,
        path: '/list-results',
        component: lazy(() => import('../pages/result/analysis'))
    },
    {
        guest: false,
        path: '/publish-result',
        component: lazy(() => import('../pages/result/publish'))
    },

    // bank
    {
        guest: false,
        path: '/create-bank-account',
        component: lazy(() => import('../pages/bank/form'))
    },
    {
        guest: false,
        path: '/edit-bank-account',
        component: lazy(() => import('../pages/bank/form'))
    },
    {
        guest: false,
        path: '/list-bank-account',
        component: lazy(() => import('../pages/bank/list'))
    },
    {
        guest: false,
        path: '/view-bank-account',
        component: lazy(() => import('../pages/bank/view'))
    },

    // mark
    {
        guest: false,
        path: '/create-mark',
        component: lazy(() => import('../pages/mark/form'))
    },
    {
        guest: false,
        path: '/create-marks',
        component: lazy(() => import('../pages/mark/marking'))
    },
    {
        guest: false,
        path: '/list-mark',
        component: lazy(() => import('../pages/mark/list'))
    },
    {
        guest: false,
        path: '/list-marks',
        component: lazy(() => import('../pages/mark/marks'))
    },

    // account group
    {
        guest: false,
        path: '/create-account-group',
        component: lazy(() => import('../pages/accountGroup/form'))
    },
    {
        guest: false,
        path: '/edit-account-group',
        component: lazy(() => import('../pages/accountGroup/form'))
    },
    {
        guest: false,
        path: '/list-account-group',
        component: lazy(() => import('../pages/accountGroup/list'))
    },
    {
        guest: false,
        path: '/view-account-group',
        component: lazy(() => import('../pages/accountGroup/view'))
    },

    // character category
    {
        guest: false,
        path: '/create-character-category',
        component: lazy(() => import('../pages/character/form'))
    },
    {
        guest: false,
        path: '/edit-character-category',
        component: lazy(() => import('../pages/character/form'))
    },
    {
        guest: false,
        path: '/list-character-category',
        component: lazy(() => import('../pages/character/list'))
    },
    {
        guest: false,
        path: '/view-character-category',
        component: lazy(() => import('../pages/character/view'))
    },

    // grade
    {
        guest: false,
        path: '/create-grade',
        component: lazy(() => import('../pages/grade/form'))
    },
    {
        guest: false,
        path: '/list-grade',
        component: lazy(() => import('../pages/grade/list'))
    },
    {
        guest: false,
        path: '/edit-grade',
        component: lazy(() => import('../pages/grade/form'))
    },
    {
        guest: false,
        path: '/view-grade',
        component: lazy(() => import('../pages/grade/view'))
    },

    // exam
    {
        guest: false,
        path: '/create-exam',
        component: lazy(() => import('../pages/exam/form'))
    },
    {
        guest: false,
        path: '/list-exam',
        component: lazy(() => import('../pages/exam/list'))
    },
    {
        guest: false,
        path: '/edit-exam',
        component: lazy(() => import('../pages/exam/form'))
    },
    {
        guest: false,
        path: '/view-exam',
        component: lazy(() => import('../pages/exam/view'))
    },
    {
        guest: false,
        path: '/create-info-exam',
        component: lazy(() => import('../pages/exam/info'))
    },
    {
        guest: false,
        path: '/edit-info-exam',
        component: lazy(() => import('../pages/exam/info'))
    },
    {
        guest: false,
        path: '/list-info-exam',
        component: lazy(() => import('../pages/exam/infoList'))
    },
    {
        guest: false,
        path: '/view-info-exam',
        component: lazy(() => import('../pages/exam/viewExamInfo'))
    },

    // NEWS
    {
        guest: false,
        path: '/create-news',
        component: lazy(() => import('../pages/news/form'))
    },
    {
        guest: false,
        path: '/edit-news',
        component: lazy(() => import('../pages/news/form'))
    },
    {
        guest: false,
        path: '/list-news',
        component: lazy(() => import('../pages/news/list'))
    },
    {
        guest: false,
        path: '/view-news',
        component: lazy(() => import('../pages/news/view'))
    },

    // academic
    {
        guest: true,
        path: '/academic',
        component: lazy(() => import('../pages/features/academic'))
    },

    // class subject
    {
        guest: false,
        path: "/create-class-subject",
        component: lazy(() => import('../pages/classSubject/form'))
    },
    {
        guest: false,
        path: "/view-class-subject",
        component: lazy(() => import('../pages/classSubject/view'))
    },
    {
        guest: false,
        path: "/list-class-subject",
        component: lazy(() => import('../pages/classSubject/list'))
    },

    // subject teacher 
    {
        guest: false,
        path: "/create-teacher-subject",
        component: lazy(() => import('../pages/subjectTeacher/form'))
    },
    {
        guest: false,
        path: "/list-teacher-subject",
        component: lazy(() => import('../pages/subjectTeacher/list'))
    },

    // academic year
    {
        guest: false,
        path: "/create-academic-year",
        component: lazy(() => import('../pages/academicYear/form'))
    },
    {
        guest: false,
        path: "/edit-academic-year",
        component: lazy(() => import('../pages/academicYear/form'))
    },
    {
        guest: false,
        path: "/list-academic-year",
        component: lazy(() => import('../pages/academicYear/list'))
    },
    {
        guest: false,
        path: "/view-academic-year",
        component: lazy(() => import('../pages/academicYear/view'))
    },

    // attendance
    {
        guest: false,
        path: "/create-attendance",
        component: lazy(() => import('../pages/attendance/form'))
    },
    {
        guest: false,
        path: '/create-attendances',
        component: lazy(() => import('../pages/attendance/attendance'))
    },

    // school
    {
        guest: false,
        path: "/system-setting",
        component: lazy(() => import('../pages/school/settings'))
    },

    // create
    {
        guest: false,
        path: "/create-school",
        component: lazy(() => import('../pages/school/form'))
    },

    {
        guest: false,
        path: "/list-school",
        component: lazy(() => import('../pages/school/list'))
    },

    {
        guest: false,
        path: "/edit-school",
        component: lazy(() => import('../pages/school/form'))
    },
    {
        guest: false,
        path: '/view-school',
        component: lazy(() => import('../pages/school/view'))
    },

    // allowance
    {
        guest: false,
        path: '/create-allowance',
        component: lazy(() => import('../pages/allowance/form'))
    },
    {
        guest: false,
        path: '/edit-allowance',
        component: lazy(() => import('../pages/allowance/form'))
    },
    {
        guest: false,
        path: '/list-allowance',
        component: lazy(() => import('../pages/allowance/list'))
    },
    {
        guest: false,
        path: '/allocate-allowance',
        component: lazy(() => import('../pages/allowance/member'))
    },

    // deduction
    {
        guest: false,
        path: '/create-deduction',
        component: lazy(() => import('../pages/deduction/form'))
    },
    {
        guest: false,
        path: '/edit-deduction',
        component: lazy(() => import('../pages/deduction/form'))
    },
    {
        guest: false,
        path: '/list-deduction',
        component: lazy(() => import('../pages/deduction/list'))
    },
    {
        guest: false,
        path: '/allocate-deduction',
        component: lazy(() => import('../pages/deduction/member'))
    },

    // pension
    {
        guest: false,
        path: '/create-pension',
        component: lazy(() => import('../pages/pension/form'))
    },
    {
        guest: false,
        path: '/edit-pension',
        component: lazy(() => import('../pages/pension/form'))
    },
    {
        guest: false,
        path: '/list-pension',
        component: lazy(() => import('../pages/pension/list'))
    },
    {
        guest: false,
        path: '/allocate-pension',
        component: lazy(() => import('../pages/pension/member'))
    },

    // salary
    {
        guest: false,
        path: '/create-salary',
        component: lazy(() => import('../pages/salary/form'))
    },

    // hostel
    {
        guest: false,
        path: '/create-hostel',
        component: lazy(() => import('../pages/hostel/form'))
    },
    {
        guest: false,
        path: '/edit-hostel',
        component: lazy(() => import('../pages/hostel/form'))
    },
    {
        guest: false,
        path: '/list-hostel',
        component: lazy(() => import('../pages/hostel/list'))
    },
    {
        guest: false,
        path: '/view-hostel',
        component: lazy(() => import('../pages/hostel/view'))
    },

    // room
    {
        guest: false,
        path: '/create-room',
        component: lazy(() => import('../pages/room/form'))
    },
    {
        guest: false,
        path: '/edit-room',
        component: lazy(() => import('../pages/room/form'))
    },
    {
        guest: false,
        path: '/list-room',
        component: lazy(() => import('../pages/room/list'))
    },
    // {
    //     guest: false,
    //     path: '/view-room',
    //     component: lazy(() => import('../pages/room/view'))
    // },


    // comment per student on report
    {
        guest: false,
        path: '/comment-per-student',
        component: lazy(() => import('../pages/comment/form'))
    },

    // comment per student on report
    {
        guest: false,
        path: '/comment-report',
        component: lazy(() => import('../pages/comment/comment'))
    },

    // timetable
    {
        guest: false,
        path: '/create-timetable',
        component: lazy(() => import('../pages/timetable/form'))
    },
    {
        guest: false,
        path: '/create-timetables',
        component: lazy(() => import('../pages/timetable/table'))
    },
    {
        guest: false,
        path: '/edit-timetable',
        component: lazy(() => import('../pages/timetable/form'))
    },
    {
        guest: false,
        path: '/list-timetable',
        component: lazy(() => import('../pages/timetable/list'))
    },
    {
        guest: false,
        path: '/view-timetable',
        component: lazy(() => import('../pages/timetable/view'))
    },

    // id
    {
        guest: false,
        path: '/print-id',
        component: lazy(() => import('../pages/idCard/form'))
    },

    // e-learning
    {
        guest: false,
        path: '/lab',
        component: lazy(() => import('../pages/digital/lab'))
    },
    {
        guest: false,
        path: '/chemistry-lab',
        component: lazy(() => import('../pages/digital/chemistry/form'))
    },
    {
        guest: false,
        path: '/bios-lab',
        component: lazy(() => import('../pages/digital/biology/form'))
    },
    {
        guest: false,
        path: '/physics-lab',
        component: lazy(() => import('../pages/digital/physics/form'))
    },
    {
        guest: false,
        path: '/agriculture-lab',
        component: lazy(() => import('../pages/digital/agriculture/form'))
    },

    // chemistry
    {
        guest: false,
        path: '/chemistry-form-one-lab',
        component: lazy(() => import('../pages/digital/chemistry/One/form'))
    },
    {
        guest: false,
        path: '/chemistry-form-two-lab',
        component: lazy(() => import('../pages/digital/chemistry/Two/form'))
    },
    {
        guest: false,
        path: '/chemistry-form-three-lab',
        component: lazy(() => import('../pages/digital/chemistry/Three/form'))
    },
    {
        guest: false,
        path: '/chemistry-form-four-lab',
        component: lazy(() => import('../pages/digital/chemistry/Four/form'))
    },
    {
        guest: false,
        path: '/chemistry-form-five-lab',
        component: lazy(() => import('../pages/digital/chemistry/Five/form'))
    },
    {
        guest: false,
        path: '/chemistry-form-six-lab',
        component: lazy(() => import('../pages/digital/chemistry/Six/form'))
    },

    // bio
    {
        guest: false,
        path: '/bios-form-one-lab',
        component: lazy(() => import('../pages/digital/biology/One/form'))
    },
    {
        guest: false,
        path: '/bios-form-two-lab',
        component: lazy(() => import('../pages/digital/biology/Two/form'))
    },
    {
        guest: false,
        path: '/bios-form-three-lab',
        component: lazy(() => import('../pages/digital/biology/Three/form'))
    },
    {
        guest: false,
        path: '/bios-form-four-lab',
        component: lazy(() => import('../pages/digital/biology/Four/form'))
    },
    {
        guest: false,
        path: '/bios-form-five-lab',
        component: lazy(() => import('../pages/digital/biology/Five/form'))
    },
    {
        guest: false,
        path: '/bios-form-six-lab',
        component: lazy(() => import('../pages/digital/biology/Six/form'))
    },

    // phyz
    {
        guest: false,
        path: '/physics-form-one-lab',
        component: lazy(() => import('../pages/digital/physics/One/form'))
    },
    {
        guest: false,
        path: '/physics-form-two-lab',
        component: lazy(() => import('../pages/digital/physics/Two/form'))
    },
    {
        guest: false,
        path: '/physics-form-three-lab',
        component: lazy(() => import('../pages/digital/physics/Three/form'))
    },
    {
        guest: false,
        path: '/physics-form-four-lab',
        component: lazy(() => import('../pages/digital/physics/Four/form'))
    },
    {
        guest: false,
        path: '/physics-form-five-lab',
        component: lazy(() => import('../pages/digital/physics/Five/form'))
    },
    {
        guest: false,
        path: '/physics-form-six-lab',
        component: lazy(() => import('../pages/digital/physics/Six/form'))
    },

    // agr
    {
        guest: false,
        path: '/agriculture-form-one-lab',
        component: lazy(() => import('../pages/digital/agriculture/One/form'))
    },
    {
        guest: false,
        path: '/agriculture-form-two-lab',
        component: lazy(() => import('../pages/digital/agriculture/Two/form'))
    },
    {
        guest: false,
        path: '/agriculture-form-three-lab',
        component: lazy(() => import('../pages/digital/agriculture/Three/form'))
    },
    {
        guest: false,
        path: '/agriculture-form-four-lab',
        component: lazy(() => import('../pages/digital/agriculture/Four/form'))
    },
    {
        guest: false,
        path: '/agriculture-form-five-lab',
        component: lazy(() => import('../pages/digital/agriculture/Five/form'))
    },
    {
        guest: false,
        path: '/agriculture-form-six-lab',
        component: lazy(() => import('../pages/digital/agriculture/Six/form'))
    },

    // books
    {
        guest: false,
        path: '/book',
        component: lazy(() => import('../pages/books/book'))
    },

    // books
    {
        guest: false,
        path: '/pre-books',
        component: lazy(() => import('../pages/books/pre'))
    },

    {
        guest: false,
        path: '/std1',
        component: lazy(() => import('../pages/books/std1'))
    },

    {
        guest: false,
        path: '/std2',
        component: lazy(() => import('../pages/books/std2'))
    },

    {
        guest: false,
        path: '/std3',
        component: lazy(() => import('../pages/books/std3'))
    },

    {
        guest: false,
        path: '/std4',
        component: lazy(() => import('../pages/books/std4'))
    },

    {
        guest: false,
        path: '/std5',
        component: lazy(() => import('../pages/books/std5'))
    },

    {
        guest: false,
        path: '/std6',
        component: lazy(() => import('../pages/books/std6'))
    },

    {
        guest: false,
        path: '/std7',
        component: lazy(() => import('../pages/books/std7'))
    },

    {
        guest: false,
        path: '/form1',
        component: lazy(() => import('../pages/books/form1'))
    },

    {
        guest: false,
        path: '/form2',
        component: lazy(() => import('../pages/books/form2'))
    },

    {
        guest: false,
        path: '/form3',
        component: lazy(() => import('../pages/books/form3'))
    },

    {
        guest: false,
        path: '/form4',
        component: lazy(() => import('../pages/books/form4'))
    },

    {
        guest: false,
        path: '/form5',
        component: lazy(() => import('../pages/books/form5'))
    },

    {
        guest: false,
        path: '/form6',
        component: lazy(() => import('../pages/books/form6'))
    },


    // notes
    {
        guest: false,
        path: '/create-notes',
        component: lazy(() => import('../pages/notes/form'))
    },

    {
        guest: false,
        path: '/list-notes',
        component: lazy(() => import('../pages/notes/list'))
    },

    // schedule
    {
        guest: false,
        path: '/create-schedule',
        component: lazy(() => import('../pages/schedule/form'))
    },

    {
        guest: false,
        path: '/list-schedule',
        component: lazy(() => import('../pages/schedule/list'))
    },

    // kiswahili
    {
        guest: false,
        path: '/kiswahili-books',
        component: lazy(() => import('../pages/books/kiswahili/form'))
    },

    // features
    {
        guest: true,
        path: "/features",
        component: lazy(() => import('../pages/features/features'))
    },

    {
        guest: true,
        path: "/about",
        component: lazy(() => import('../pages/home/about'))
    },

    {
        guest: true,
        path: "/contact",
        component: lazy(() => import('../pages/home/contact'))
    },

    {
        guest: true,
        path: "/digital-learning",
        component: lazy(() => import('../pages/features/digital'))
    },

    {
        guest: true,
        path: "/user-management",
        component: lazy(() => import('../pages/features/admission'))
    },

    // other
    {
        guest: true,
        path: '/other',
        component: lazy(() => import('../pages/features/other'))
    },

    // sms and email
    {
        guest: true,
        path: '/sms-and-email',
        component: lazy(() => import('../pages/features/communication'))
    },

    // finance and accounting
    {
        guest: true,
        path: '/finance-and-accounting',
        component: lazy(() => import('../pages/features/account'))
    },

    // ANY ROUTE OUTSIDE
    {
        guest: true,
        path: '*',
        component: lazy(() => import('../pages/user/login'))
    },

    // // ANY ROUTE INSIDE
    // {
    //     guest: false,
    //     path: '*',
    //     component: lazy(() => import('../pages/not_found'))
    // }
]

export default routes;