// IMPORT DEPENDENCIES
import { decrypt, encrypt, has_enable_encryption, serverUrl } from "."
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// CLASS FOR API REQUEST
class API {
    constructor(state, dispatch) {
        this.state = state
        this.dispatch = dispatch
        this.headers = {
            "Content-Type": "application/json",
            "token": ""
        }
    }

    // clearing component state
    unMount = () => this.dispatch({ type: "unMount", value: "" })

    // create or update api
    async createOrUpdate(options) {
        try {

            // enable loading
            this.dispatch({ type: "loading", value: options.loading })

            const response = await (await fetch(`${serverUrl}/api/${options.route}`, {
                mode: "cors",
                method: options.method,
                body: JSON.stringify(has_enable_encryption ? encrypt(options.body) : options.body),
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ type: "loading", value: false })

            if (has_enable_encryption) {
                return decrypt(response)
            }

            return response

        } catch (error) {
            this.dispatch({ type: "loading", value: false })
            return { success: false, message: error.message }
        }
    }

    // read or delete api
    async readOrDelete(options) {
        try {
            // enable loading
            this.dispatch({ type: 'loading', value: options.loading })

            const newParams = has_enable_encryption ? `payload=${encrypt(options.parameters).payload}` : options.parameters

            let response = await fetch(`${serverUrl}/api/${options.route}?${newParams}`, {
                mode: 'cors',
                method: options.method,
                headers: this.headers,
            })
            response = await response.json()

            // disable loading
            this.dispatch({ type: 'loading', value: false })

            if (has_enable_encryption) {
                return decrypt(response)
            }

            return response
        } catch (error) {
            this.dispatch({ type: "loading", value: false })
            return { success: false, message: error.message }
        }
    }

    // handle print
    handlePrint = () => {
        try {
            // Check if the location object is defined
            if (typeof window.location !== 'undefined') {
                var originalTitle = document.title;
                window.print();
                document.title = originalTitle;
            } else {
                // Handle the error
                this.dispatch({ type: "notification", value: { message: "Location is undefined", type: "error" } })
            }
        } catch (error) {
            this.dispatch({ type: "notification", value: error.message })
        }
    }

     handleExcel = async (dataTable, columnMapping) => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet1');
    
            // Map column headers
            const headers = Object.keys(columnMapping).map(key => ({
                header: columnMapping[key],
                key: key
            }));
            worksheet.columns = headers;
    
            // Add rows to the worksheet
            dataTable.forEach(item => {
                const row = {};
                for (const columnName in columnMapping) {
                    if (columnMapping.hasOwnProperty(columnName) && item[columnName] !== undefined) {
                        row[columnName] = item[columnName];
                    }
                }
                worksheet.addRow(row);
            });
    
            // Write to buffer
            const buffer = await workbook.xlsx.writeBuffer();
            
            // Create a blob from the buffer and save the file
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'table.xlsx');
        } catch (error) {
            console.error(error);  // Log any errors
        }
    };
    

    // handle input change
    handleInputChange = (event) => {
        try {
            // destructuring name and value
            const { name, value } = event.target

            // change state value
            this.dispatch({ type: name, value: value })


        } catch (error) {
            this.dispatch({ type: "notification", value: error.message })
        }
    }

    // handle multi select change
    handleMultiselectChange = (stateKey) => {
        return (selectedOptions) => {
            try {
                const selectedValues = selectedOptions.map((option) => option.value);
                this.dispatch({ type: stateKey, value: selectedValues });
            } catch (error) {
                this.dispatch({
                    type: "notification",
                    value: {
                        message: error.message,
                        type: "error",
                    },
                })
            }
        }
    }

    getDynamicLimits = (totalDocuments) => {
        try {
            if (totalDocuments <= 100) {
                return Array.from({ length: Math.ceil(totalDocuments / 10) }, (_, index) => (index + 1) * 10);
            } else {
                const numberOfGroups = 5;
                const groupSize = Math.ceil(totalDocuments / numberOfGroups);
                return Array.from({ length: numberOfGroups }, (_, index) => (index + 1) * groupSize);
            }
        } catch (error) {
            this.dispatch({ type: "notification", value: { message: error.message, type: "error" } })
        }
    };
}


// EXPORT
export default API