// IMPORT DEPENDENCIES
import React from 'react';

// TOAST
const Toast = React.memo(({ message, type }) => {
  let colorClass = '';
  let iconName = '';

  switch (type) {
    case 'info':
      colorClass = 'info';
      iconName = 'info';
      break;
    case 'warn':
      colorClass = 'warning';
      iconName = 'warning';
      break;
    case 'error':
      colorClass = 'error';
      iconName = 'error';
      break;
    case 'success':
      colorClass = 'success';
      iconName = 'task_alt';
      break;
    default:
      break;
  }

  return (
    <div className="message">
      <div className={`message-icon ${colorClass}`}>
        <span className="material-icons-round">
          {iconName}
        </span>
      </div>
      <div className={`message-body`}>
        <p>{message}</p>
      </div>
    </div>
  );
});

// EXPORT
export default Toast;
