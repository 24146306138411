import initial_state from "./initial_state"

// APPLICATION REDUCER
function reducer(state, action) {
    try {

        // destructing state and value from the action
        const { type, value } = action

        const newState = { ...state, [type]: value }

        // values that has no impact on the other values
        if ((type === 'loading') || (type === "notification") || (type === "authenticated") || (type === 'disabled'))
            return newState

        // values that has impact to other state values
        // errors
        if (type.includes("Error"))
            return { ...newState, disabled: true }

        // empty
        if (type === "unMount")
            return { ...initial_state, authenticated: state.authenticated, }

        // no error
        return { ...newState, disabled: false, [`${type}Error`]: "" }

    } catch (error) {
        console.log(error.message)
        return state
    }
}


// EXPORT
export default reducer
