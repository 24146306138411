// IMPORT DEPENDENCIES
import React from "react"
import Toast from "./toast"

// GLOBAL CONTAINER
export const GlobalContainer = React.memo((props) => {
    return (
        <main>
            {props.message && props.message.trim() ? <Toast message={props.message} icon={props.icon} type={props.type}  /> : null}
            {props.children}
        </main>
    )
})
