import React, { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext({ theme: "light", setTheme: () => { } });

export const ThemeProvider = ({ children }) => {
    // Initialize state with value from localStorage or fallback to 'light'
    const [theme, setTheme] = useState(() => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem('eduTheme') || 'light';
        }
        return 'light';
    });

    useEffect(() => {
        const savedThemeLocal = localStorage.getItem('eduTheme');
        if (savedThemeLocal) {
            setTheme(savedThemeLocal);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('eduTheme', theme);
        document.body.className = theme; // Apply the theme to the body
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            <div className={`theme--${theme}`}>{children}</div>
        </ThemeContext.Provider>
    );
};
