import React from 'react'

const Humberg = React.memo((props) => {
    return (
        <div className="menu-icon" id={props.id} onClick={props.onClick}>
            <input className="menu-icon__cheeckbox" type="checkbox" />
            <div>
                <span></span>
                <span></span>
            </div>
        </div>
    )
})

export default Humberg