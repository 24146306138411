import React, { useContext } from 'react';
import { ThemeContext } from '../context/themeContext';

const ThemeSwitcher = () => {
    const { theme, setTheme } = useContext(ThemeContext);

    const toggleTheme = () => {
        setTheme(prevTheme => (prevTheme === 'dark' ? 'light' : 'dark'));
    };

    return (
        <div className="theme-switcher-container">
            <button onClick={toggleTheme} aria-label="toggle theme" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <span
                    className="material-icons-round"
                >
                    {theme === 'dark' ? 'light_mode' : 'dark_mode'}
                </span>
            </button>
        </div>
    );
};

export default ThemeSwitcher;
